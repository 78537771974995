import { ElMessage } from "element-plus";
import { WlMessageBox } from "wltech-ui";
import { getCosImageUrl } from "@/utils";
import * as directives from "@/utils/directives";
import type { Plugin } from "vue";

import "element-plus/es/components/message/style/css";
import "wltech-ui/es/components/message-box/style/css";

export default {
  install(app) {
    Object.entries(directives).forEach(([key, val]) => {
      app.directive(key, val);
    });
    app.config.globalProperties.$message = ElMessage;
    app.config.globalProperties.$confirm = WlMessageBox.confirm;
    app.config.globalProperties.$alert = WlMessageBox.alert;
    app.config.globalProperties.getCosImageUrl = getCosImageUrl;
  },
} as Plugin;
