import { defineStore } from "pinia";
import { reactive, toRefs } from "vue";

export const useCommon = defineStore("common", () => {
  const state = reactive({
  });
  const getters = {};
  const actions = {};
  return {
    ...toRefs(state),
    ...getters,
    ...actions,
  };
});
