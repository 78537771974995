<template>
  <ww-open-data
    v-if="isTryVersion"
    ref="opendata"
    :type="type"
    :openid="openid"
  ></ww-open-data>
  <span v-else>{{ openid }}</span>
</template>
<script lang="ts" setup>
import { useUserStore } from "@/stores";
import { storeToRefs } from "pinia";
// import { useOpenData } from "@/hooks";
// import { watch } from "vue";
const props = withDefaults(
  defineProps<{
    openid: string;
    type?: "userName" | "departmentName"; //待补全
  }>(),
  {
    type: "userName",
  }
);
const userStore = useUserStore();
const { isTryVersion } = storeToRefs(userStore);
// const { opendata, update } = useOpenData();
// watch(() => props.openid, update);
</script>
