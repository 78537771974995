import axios from "axios";
import type {
  CreateAxiosDefaults,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { camelizeKeys, decamelizeKeys, decamelize } from "humps";

// 创建 axios 实例
const createAxiosInstance = (
  baseURL = import.meta.env.VITE_APP_API,
  options?: Omit<CreateAxiosDefaults, "baseURL">
) =>
  axios.create(
    Object.assign(
      {
        baseURL,
        withCredentials: true,
        timeout: 60000, // 请求超时时间
        responseType: "json",
        headers: {
          common: {
            "Cache-Control": "no-cache",
          },
          get: {
            "If-Modified-Since": 0,
          },
        },
      },
      options
    )
  );

// 异常拦截处理器
export const errorHandler = (error: any) => {
  return Promise.reject(error?.response?.data);
};
// 请求拦截处理器
export const reqInterceptor = (config: InternalAxiosRequestConfig) => {
  const params = {
    // corpId: "wpK9-UBwAA_FpZOPXmb_I1sI-yVd4YmQ",
  };

  config.params = Object.assign({}, params, config.params);

  if (config.params) {
    config.params = decamelizeKeys(config.params);
    if (config.params.sort_param?.sort?.length) {
      config.params.sort_param.sort.forEach((sort: any) => {
        if (sort.prop) {
          sort.prop = decamelize(sort.prop);
        }
      });
    }
  }
  if (config.data) {
    config.data = decamelizeKeys(config.data);
    if (config.data.sort_param?.sort?.length) {
      config.data.sort_param.sort.forEach((sort: any) => {
        if (sort.prop) {
          sort.prop = decamelize(sort.prop);
        }
      });
    }
  }
  return config;
};
// 响应拦截处理器
export const resInterceptor = (response: AxiosResponse) => {
  if (
    response.data
    // &&
    // response.headers["content-type"] === "application/json"
  ) {
    response.data = camelizeKeys(response.data);
  }

  return response.data;
};

// 默认主域名请求
const request = createAxiosInstance();
request.interceptors.request.use(reqInterceptor, errorHandler);
request.interceptors.response.use(resInterceptor, errorHandler);
export default request;
// 其他域名请求
// export const otherRequest = createAxiosInstance("https://some.domian.test")
// otherRequest.interceptors.request.use(reqInterceptor, errorHandler);
// otherRequest.interceptors.response.use(resInterceptor, errorHandler);
