import { defineStore } from "pinia";
import { computed, reactive, toRefs } from "vue";
import { deptTypeList } from "@/api";
interface DeptItem {
  id: string;
  name: string;
  virtualDepts?: string[];
}

export const useDeptStore = defineStore("dept", () => {
  const getDefaultList = () => ({
    warZones: [] as DeptItem[],
    directStores: [] as DeptItem[],
    leagueStores: [] as DeptItem[],
  });
  const state = reactive({
    deptList: getDefaultList(),
    isLoading: false,
  });
  const getters = {
    availableDeptList: computed(() => {
      const { warZones, directStores, leagueStores } = state.deptList;
      return Object.assign(
        {},
        warZones.length && { warZones },
        directStores.length && { directStores },
        leagueStores.length && { leagueStores }
      );
    }),
    deptMap: computed(() => {
      const { warZones, directStores, leagueStores } = state.deptList;
      const map: Record<string | number, any> = {};
      warZones.forEach((item) => {
        map[item.id] = item;
      });
      directStores.forEach((item) => {
        map[item.id] = item;
      });
      leagueStores.forEach((item) => {
        map[item.id] = item;
      });
      return map;
    }),
  };
  const actions = {
    updateDeptTypeList: async () => {
      state.isLoading = true;
      try {
        const res = await deptTypeList();
        state.deptList = res.data || getDefaultList();
      } finally {
        state.isLoading = false;
      }
    },
  };
  return {
    ...toRefs(state),
    ...getters,
    ...actions,
  };
});

export const groupNames = {
  warZones: "战区",
  directStores: "直营店",
  leagueStores: "加盟店",
};
