import { createApp } from "vue";
import { createPinia } from "pinia";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import "dayjs/locale/zh-cn";

import App from "./App.vue";
import router from "./router";
import WlSvg from "@wltech/svg";
import plugins from "@/utils/plugins";

import "@wltech/style";
import "@wltech/style/dist/ant-override.css";
import "@wltech/style/dist/ele-override.css";
import "@/assets/scss/output.scss";
import "./assets/main.css";

const app = createApp(App);
// globalProperties添加全局工具移动至@/utils/plugins
// app.config.globalProperties.getCosImageUrl = getCosImageUrl;
app.use(createPinia());
app.use(router);
app.use(WlSvg, import.meta.env.VITE_APP_SUB);
app.use(plugins);
app.mount("#app");
dayjs.locale("zh-cn");
dayjs.extend(quarterOfYear);
