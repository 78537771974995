import { reactive, computed, toRefs } from "vue";
import { defineStore } from "pinia";
import { getCurrentUser } from "@/api";
const loadings: any = {};

export const useUserStore = defineStore("user", () => {
  const state = reactive({
    userInfo: null,
    roles: [] as Array<string>,
  });
  const getters = {};
  const actions = {
    async getCurrentUser() {
      const { data } = await getCurrentUser();
      state.userInfo = data.user;
      state.roles = data.roles;
    },
  };
  return {
    ...toRefs(state),
    ...getters,
    ...actions,
  };
});
