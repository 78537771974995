<template>
  <wl-layout v-bind="layoutConf" v-model:selectedKeys="selectedKeys">
    <template v-slot:logo>
      <div style="height: 64px; width: 212px" class="flex-center">
        <img src="@/assets/images/logo-neta.png" style="max-height: 100%; max-width: 100%" />
      </div>
    </template>
    <template v-slot:rightContent>
      <div>
        <RightContent />
      </div>
    </template>
    <div class="main-container card">
      <h2>{{ $route.meta.title }}</h2>
      <router-view />
    </div>
  </wl-layout>
</template>

<script lang="ts" setup>
import { ref, watch, type Ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getMenuData, clearMenuItem } from "wltech-ui";

const router = useRouter();
const route = useRoute();
const layoutConf = ref(getMenuData(clearMenuItem(router.getRoutes())));
const selectedKeys: Ref<string[]> = ref([]);
watch(
  () => route.path,
  () => {
    layoutConf.value = getMenuData(clearMenuItem(router.getRoutes()));
    const matched = router.currentRoute.value.matched.concat();
    selectedKeys.value = matched.map((r) => r.path);
  },
  {
    immediate: true,
  }
);
</script>
<style>
html,
body,
#app,
.ant-layout,
.ant-layout-footer {
  background: none;
}
html .ant-layout-header {
  background: #fff;
}
html .ant-layout-header .ant-menu {
  background-color: inherit;
}
</style>
