import { defineStore } from "pinia";
import { computed, reactive, toRefs } from "vue";
import { createNetaOptApi, type DimensionType } from "@/api";
interface DeptItem {
  id: string;
  name: string;
  virtualDepts?: string[];
}

export const usePermissionStore = defineStore("permission", () => {
  const getDefaultPermission = () => ({
    warZones: [] as DeptItem[],
    directStores: [] as DeptItem[],
    leagueStores: [] as DeptItem[],
  });
  const state = reactive({
    basic: [],
    personnelChange: [],
  });
  const getters = {};
  const actions = {
    updatePermissionList: async (dimension: DimensionType) => {
      if (!dimension) {
        return Promise.reject();
      }
      const res = await createNetaOptApi(dimension)();
      state[dimension] = res.data || getDefaultPermission();
    },
  };
  return {
    ...toRefs(state),
    ...getters,
    ...actions,
  };
});
