import MainLayout from "@/layouts/MainLayout.vue";
import { RouterView } from "vue-router";

export const asyncRouterMap = [
  {
    path: "/",
    name: "Root", //不能为空
    component: MainLayout,
    meta: { title: "" },
    redirect: "/manage/info",
    children: [
      {
        path: "/manage",
        name: "ManageLayout",
        redirect: "/manage/info",
        component: RouterView,
        meta: {
          title: "人员管理",
        },
        children: [
          {
            path: "info",
            name: "ManageInfo",
            component: () => import("@/views/manage/Info.vue"),
            meta: {
              title: "岗位变更信息",
            },
          },
          {
            path: "detail",
            name: "ManageDetail",
            component: () => import("@/views/manage/Detail.vue"),
            meta: {
              title: "DMS员工详细信息",
            },
          },
        ],
      },
      {
        path: "/statistics",
        name: "Statistics",
        component: () => import("@/views/statistics/index.vue"),
        // redirect: "/statistics/basic",
        meta: {
          title: "数据统计",
          roles: ["headquarters", "areaPersionel"],
        },
        // children: [
        //   {
        //     path: "basic",
        //     name: "StatisticsBasic",
        //     component: () => import("@/views/statistics/Basic.vue"),
        //     meta: {
        //       title: "基础数据",
        //     },
        //   },
        //   {
        //     path: "change",
        //     name: "StatisticsChange",
        //     component: () => import("@/views/statistics/Change.vue"),
        //     meta: {
        //       title: "人员异动数据",
        //     },
        //   },
        // ],
      },
    ],
  },
];
/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: () => import("@/views/login/Login.vue"),
  //   meta: { title: "登陆中" },
  //   hidden: true,
  // },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/exception/404.vue"),
    meta: { title: "404", hideInMenu: false },
  },
];
