import { get, post } from "@/utils";

// export const netaHome = (data = {}) => get("/neta/home", data);

export const basicByDate = (data = {}) =>
  get("/neta/statistics/basic/byDate", data);

export const basicByType = (data = {}) =>
  get("/neta/statistics/basic/byType", data);

export const personnelChangeByDate = (data = {}) =>
  get("/neta/statistics/personnelChange/byDate", data);

export const personnelChangeByType = (data = {}) =>
  get("/neta/statistics/personnelChange/byType", data);

export const jobMessage = (data = {}) => get("/neta/user/job/message", data);

export const jobList = (data = {}) => get("/neta/user/job/list", data);

export const userList = (data = {}) => post("/neta/user/list", data);
export const positionConfirm = (data = {}) =>
  get("/neta/user/position/confirm", data);

export const deptTypeList = (data = {}) =>
  get("/neta/dept/type/list", data, { isCache: true });

export const createNetaOptApi =
  (dimension: string) =>
  (data = {}) =>
    get(`/neta/optional/${dimension}`, data, { isCache: true });
export type DimensionType = "basic" | "personnelChange";
