import { createRouter, createWebHistory as createHistory } from "vue-router";
import { asyncRouterMap, constantRouterMap } from "@/config";
import routeWithPermission from "@/permission";

const router = createRouter({
  history: createHistory(),
  routes: [...asyncRouterMap, ...constantRouterMap],
});
routeWithPermission(router);
export default router;
