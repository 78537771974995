import { useUserStore } from "@/stores";
import { setDocumentTitle } from "@/utils";
import { isNavigationFailure, type Router } from "vue-router";
import { decamelize } from "humps";
import { ElMessage } from "element-plus";
import { allowList, asyncRouterMap } from "@/config";
import { storeToRefs } from "pinia";
const routeWithPermission = (router: Router) => {
  router.beforeEach((to, from, next) => {
    const userStore = useUserStore();
    const { userInfo, roles } = storeToRefs(userStore);
    if (to.meta && typeof to.meta.title !== "undefined") {
      setDocumentTitle((to.meta.title as string) + " - 卫瓴 · 哪吒汽车");
    }
    if (allowList.includes(to.name as string)) {
      next();
    } else {
      if (!userInfo.value) {
        userStore
          .getCurrentUser()
          .then(() => {
            if (!userInfo.value) {
              throw new Error("login Error");
            } else {
              router.getRoutes().forEach((route) => {
                if (
                  route.meta?.roles &&
                  !route.meta.roles.some((role) => roles.value.includes(role))
                ) {
                  route.meta.hideInMenu = true;
                  router.removeRoute(route.name as string);
                }
              });
              next();
            }
          })
          .catch(() => {
            location.href = `${import.meta.env.VITE_APP_ERP}&corp_id=${
              import.meta.env.VITE_APP_CORPOD
            }`;
          });
      } else {
        next();
      }
    }
  });

  router.afterEach((to, from, failure) => {
    if (isNavigationFailure(failure)) {
      return;
    }
    document.documentElement.setAttribute(
      "data-route-name",
      decamelize(to.name as string, { separator: "-" })
    );
  });
};
export default routeWithPermission;
